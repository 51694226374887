<template>
  <v-container class="pa-0">
    <v-row no-gutters justify="start" class="pa-0 search-box">
      <v-col id="keyword-search-input">
        <v-text-field
          outlined
          hide-details
          single-line
          full-width
          required
          flat
          dense
          clearable
          @click:clear="onClear"
          @keydown.enter.prevent="$emit('submit', queryModel)"
          clear-icon="mdi-close"
          prepend-inner-icon="mdi-magnify"
          :placeholder="$t('discovery.search_placeholder')"
          v-model="queryModel"
          data-cy="keyword-search-input"
          autocorrect="off"
          class="search-input"
          height="44px"
          label=""
          aria-labelledby="keyword-search-input"
          aria-selected="true"
          tabindex="0"
        />
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import BookingSearchMixins from '../../mixins/BookingSearchMixins';
export default {
  name: 'KeywordSearchComponent',
  mixins: [BookingSearchMixins],
  props: {
    queryKeyword: String,
  },
  data() {
    return { queryModel: this.queryKeyword, dirty: false };
  },
  watch: {
    queryModel(val, oldVal) {
      if (val != oldVal) {
        this.$emit('formChange', this.queryModel);
        this.dirty = true;
      }
    },
    queryKeyword(value) {
      this.queryModel = value;
    },
  },
  methods: {
    onClear() {
      this.queryModel = '';
      this.dirty = true;
      this.$emit('submit', this.queryModel);
    },
  },
};
</script>
<style lang="scss" scoped>
.search-box {
  width: 100%;

  @media (min-width: map-get($grid-breakpoints, lg)) {
    width: 400px;
  }

  ::v-deep {
    .v-input__prepend-inner {
      .v-icon {
        color: var(--v-primary-base) !important;
      }
    }
    .v-input__append-inner {
      margin-top: 10px !important;
    }
  }

  .search-input {
    border-radius: 8px;
    ::v-deep {
      .v-input__prepend-inner {
        margin-top: 10px !important;
      }
    }
  }
}

::v-deep .v-input {
  background: white;
}
</style>
