<template>
  <v-container fluid class="pa-0">
    <VueHeadful :title="pageTitle" />
    <v-row no-gutters>
      <v-col cols="12">
        <DiscoveryHeader
          :modalState="modalState"
          :query="query"
          @click="toggleModal"
        />
        <SearchModalComponent :modalView="modalState" @close="toggleModal" />
      </v-col>
    </v-row>
    <router-view></router-view>
  </v-container>
</template>

<script>
import VueHeadful from 'vue-headful';
import DiscoveryHeader from '@/modules/discovery/features/DiscoveryHeader';
import SearchModalComponent from '@/modules/discovery/features/search/SearchModalComponent';
import GoogleTagManagerMixins from '@/core/mixins/GoogleTagManagerMixins';
import DiscoveryPageMixins from '@/modules/discovery/mixins/DiscoveryPageMixins';
import * as Sentry from '@sentry/vue';

export default {
  name: 'DiscoveryPage',
  mixins: [GoogleTagManagerMixins, DiscoveryPageMixins],
  components: {
    DiscoveryHeader,
    VueHeadful,
    SearchModalComponent,
  },
  props: ['query'],

  data() {
    return {
      modalState: false,
    };
  },

  created() {
    Sentry.setTag('module', 'discovery');
  },

  methods: {
    toggleModal() {
      this.modalState = !this.modalState;
    },
  },

  computed: {
    pageTitle() {
      if (this.partnerName && this.cityName) {
        return `Must see in ${this.cityName} | ${this.partnerName} | Guestlogix`;
      } else if (this.partnerName) {
        return `${this.partnerName} | Guestlogix`;
      } else return '';
    },
  },
};
</script>

<style scoped>
.enhanced-discovery-header {
  margin-bottom: 20px !important;
}
</style>
