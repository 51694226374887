<template>
  <v-container class="pa-0" data-cy="SearchComponent">
    <template v-if="$vuetify.breakpoint.smAndDown && !modalState">
      <v-row no-gutters class="my-0 mx-auto" align="center" justify="center">
        <v-col cols="12" class="py-0">
          <v-container
            class="search-trigger px-0 py-2"
            @click="$emit('search')"
            id="search-result-text"
          >
            <v-text-field
              :value="searchResultText"
              hide-details
              disabled
              outlined
              rounded
              dense
              label=""
              aria-labelledby="search-result-text"
              aria-selected="true"
              tabindex="0"
              :placeholder="$t('product.input_placeholder')"
            >
              <template v-slot:append>
                <v-img
                  alt="Search button"
                  class="nav-icon ma-auto"
                  width="36"
                  height="36"
                  src="@/assets/search-button.svg"
                />
              </template>
            </v-text-field>

            <template v-if="isOpenSearchTooltip">
              <div class="search-box-location-tooltip">
                {{ $t('discovery.tell_us_where_you_going') }}
                <img
                  class="search-box-location-tooltip-image"
                  src="@/assets/close.svg"
                  alt="close"
                  @click="isOpenSearchTooltip = false"
                />
              </div>
            </template>
          </v-container>
        </v-col>
      </v-row>
    </template>

    <template v-if="$vuetify.breakpoint.mdAndUp || modalState">
      <v-form @submit.prevent="onSubmit" action="." ref="form">
        <v-row
          class="flex-column flex-sm-row justify-center justify-md-start pb-6"
          no-gutters
        >
          <template v-if="$vuetify.breakpoint.smAndDown">
            <v-col cols="12" class="py-0">
              <p class="mb-1">
                {{ $t('keywords') }}
              </p>
            </v-col>
          </template>

          <v-col
            class="pr-sm-2"
            cols="12"
            sm="6"
            md="5"
            lg="auto"
            data-cy="SearchComponent-keyword-search"
          >
            <KeywordSearchComponent
              :queryKeyword="preSelectedTextQuery"
              @formChange="onFormChange"
              @submit="onEnter"
            />
          </v-col>

          <template v-if="$vuetify.breakpoint.smAndDown">
            <v-col cols="12" class="pb-0 pt-4">
              <p class="mb-1">{{ $t('location_text') }}</p>
            </v-col>
          </template>

          <v-col
            class="pl-sm-2 pr-md-3"
            cols="12"
            sm="6"
            md="5"
            lg="auto"
            id="search-box"
            data-cy="SearchComponent-location-search"
          >
            <v-combobox
              class="search-box pb-0"
              height="44px"
              dense
              flat
              solo
              outlined
              clearable
              :items="items"
              no-filter
              :value="preSelectedSearch.city"
              :loading="citySearch.isLoading"
              :search-input.sync="search"
              @change="onChange"
              @click:clear="onClear"
              color="secondary"
              prepend-inner-icon="mdi-map-marker-outline"
              append-icon=""
              :placeholder="
                isMobile ? $t('product.search_city') : $t('product.location')
              "
              return-object
              :no-data-text="$t('product.no_cities_found')"
              label=""
              aria-labelledby="search-box"
              aria-selected="true"
              tabindex="0"
              hide-details
            >
              <template v-slot:prepend-item>
                <v-list-item
                  class="item-text"
                  ripple
                  @click="getUserLocationAndRedirect"
                >
                  <v-list-item-action>
                    <v-icon color="secondary">mdi-near-me</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title class="item-text">
                      {{ $t('current_location') }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
              <template slot="item" slot-scope="{ item }">
                <v-list-item-action class="mr-2">
                  <v-icon color="secondary">mdi-map-marker-outline</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title class="item-text">
                    {{ item.text }}
                  </v-list-item-title>
                </v-list-item-content>
              </template>
            </v-combobox>

            <template v-if="isOpenSearchTooltip">
              <div
                class="search-box-location-tooltip desktop"
                data-cy="SearchComponent-tooltip"
              >
                {{ $t('discovery.tell_us_where_you_going') }}
                <img
                  class="search-box-location-tooltip-image"
                  src="@/assets/close.svg"
                  alt="close"
                  @click="isOpenSearchTooltip = false"
                />
              </div>
            </template>
          </v-col>
          <v-col
            data-cy="SearchComponent-search-button"
            cols="12"
            md="auto"
            class="mt-5 mt-md-0 search-button"
          >
            <v-btn
              color="primary"
              type="submit"
              dense
              height="44"
              width="100%"
              depressed
            >
              {{ $t('search') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </template>
  </v-container>
</template>

<script>
import BookingSearchMixins from '@/modules/discovery/mixins/BookingSearchMixins';
import KeywordSearchComponent from './KeywordSearchComponent';
import { mapboxService } from '@/core/services/MapboxService';
import SelectedContextFlightMixins from '@/modules/flights/mixins/SelectedContextFlightMixins';
import GoogleTagManagerMixins from '@/core/mixins/GoogleTagManagerMixins';
import HotJarMixins from '@/core/mixins/HotJarMixins';

export default {
  name: 'SearchComponent',
  mixins: [
    BookingSearchMixins,
    SelectedContextFlightMixins,
    GoogleTagManagerMixins,
    HotJarMixins,
  ],
  components: { KeywordSearchComponent },
  props: {
    isMobile: {
      type: Boolean,
      default: false,
    },
    modalState: Boolean,
  },

  data() {
    return {
      preSelectedSearch: {
        city: null,
        longLat: null,
      },
      selectedItemValue: {
        text: '',
        longLat: null,
        city: null,
      },
      itemFromQuery: {
        q: this.$route.query?.q || '',
        longLat: [
          this.$route.query?.lng || null,
          this.$route.query?.lat || null,
        ],
        city: this.$route.query?.city || null,
      },
      search: null,
      timer: null,
      searchQuery: '',
      isOpenSearchTooltip: true,
      mapboxService: mapboxService,
    };
  },

  methods: {
    getUserLocationAndRedirect() {
      this.$getLocation().then(
        (coordinates) => {
          this.performReverseGeocode({
            lat: coordinates.lat,
            lng: coordinates.lng,
          });
        },
        (rejected) => {
          //Just to catch the rejection so chrome console doesn't complain
        }
      );
    },

    searchCityDebounced(cityName) {
      // cancel pending call
      clearTimeout(this.timer);

      // delay new call 400ms
      this.timer = setTimeout(() => {
        this.performCitySearch(cityName);
      }, 400);
    },

    onSubmit() {
      if (this.$vuetify.breakpoint.smAndDown) this.$emit('modalClose');
      this.onSubmitQuery();
    },

    onEnter(query) {
      this.searchQuery = query;
      if (this.$vuetify.breakpoint.mdAndUp) {
        this.onSubmit();
      }
    },

    onSubmitQuery() {
      let city = this.selectedItemValue.city || this.preSelectedSearch.city;
      let longlat =
        this.selectedItemValue.longLat || this.preSelectedSearch.longLat;
      if (this.selectedItemValue.city && this.searchQuery) {
        this.pushExperiencesMultiSearchAnalytics(
          city,
          longlat,
          this.searchQuery
        );
      } else if (this.selectedItemValue.city) {
        this.pushExperiencesSearchByLocationAnalytics(city, longlat);
        this.pushHotJarAnalytics('experiences_location_search');
      } else if (this.searchQuery) {
        this.pushExperiencesSearchByKeywordAnalytics(this.searchQuery);
      }
      if (
        this.selectedItemValue.longLat != null &&
        this.preSelectedSearch !== this.selectedItemValue.city
      ) {
        this.updateSearchQuery(this.$route.params, this.selectedItemValue);
        return;
      }
      this.updateSearchQuery(this.$route.params);
    },

    onClear() {
      this.selectedItemValue = {
        text: '',
        longLat: null,
        city: null,
      };
      this.preSelectedSearch = {
        city: null,
        longLat: null,
      };
    },

    onChange(args) {
      if (args) {
        const { text, city, longLat } = args;
        this.selectedItemValue = {
          text,
          city,
          longLat,
        };
      } else {
        this.selectedItemValue = {
          text: null,
        };
      }
    },

    async updateSearchQuery(params, query) {
      const routeName =
        this.searchQuery === ''
          ? 'discoveryWithCity'
          : 'discoverySearchResults';

      let queryObject = {};

      if (!Boolean(query)) {
        let query = Object.assign({}, this.$route.query);
        delete query['city'];
        delete query['lng'];
        delete query['lat'];
        delete query['q'];
        this.$router.replace({ query }).catch((err) => {});

        this.isOpenSearchTooltip = true;
        return false;
      } else {
        queryObject.q = this.searchQuery;
        if (!!query && query.city && query.longLat) {
          queryObject.city = query.city;
          queryObject.lng = query.longLat[0].toString();
          queryObject.lat = query.longLat[1].toString();
        }

        if (!Boolean(this.selectedItemValue.city)) {
          const city = await mapboxService.getCities(
            this.selectedContextFlight?.destination.city
          );
          queryObject.city = !this.searchQuery
            ? this.selectedContextFlight?.destination.city
            : '';
          queryObject.lng = city.features[0].center[0].toString();
          queryObject.lat = city.features[0].center[1].toString();
          queryObject.q = this.searchQuery;
        }
        if (
          this.selectedContextFlight?.destination.city ===
          this.$route.query.city
        ) {
          await this.updateSelectedCity(
            this.selectedContextFlight?.destination.city
          );
        }

        this.pushToRouterQuery(routeName, params, queryObject);
      }
    },

    pushToRouterQuery(routeName, params, queryObject) {
      //Condition just to catch the rejection so chrome console doesn't complain
      if (JSON.stringify(queryObject) !== JSON.stringify(this.$route.query)) {
        this.$router.push({
          name: routeName,
          params: params,
          query: queryObject,
        });
      }
    },

    onFormChange(value) {
      this.searchQuery = value;
    },

    async updateSelectedCity(city) {
      if (!mapboxService.geocodingClient) {
        return;
      }
      if (city) {
        if (this.$route.query.country) {
          city += ` ${this.$route.query.country}`;
        }
        let lng = Number(this.$route.query.lng);
        let lat = Number(this.$route.query.lat);
        let flightLng = this.selectedContextFlight?.destination?.longitude;
        let flightLat = this.selectedContextFlight?.destination?.latitude;
        // proximity is [lng, lat] set passed to search near these coordinates.
        // If it is set to coordinates which are exactly the same as some place
        // in the city, the search result is that place.
        // Adding 1 shifts the proximity point and finds
        // only the city. If you do not set proximity at all (null),
        // then the result will be an array of cities with the name from the query,
        // arranged by alphabet, which causes bugs (e.g. searching 'Victoria'
        // we receive 'Victoria, Australia' at the first place, although
        // the context flight destination city is 'Victoria, Canada'
        let proximity =
          lng && lat && !this.selectedContextFlight
            ? [lng + 1, lat + 1]
            : [flightLng + 1, flightLat + 1];
        const res = await mapboxService.getCitiesWithoutTypesFilter(
          city,
          proximity
        );
        if (res.features && res.features.length > 0) {
          this.preSelectedSearch = {
            city: res.features[0].place_name_en ?? res.features[0].place_name,
            longLat: res.features[0].center,
            text: res.features[0].text_en ?? res.features[0].text,
          };
          this.selectedItemValue = {
            ...this.preSelectedSearch,
            city: this.preSelectedSearch.text,
            text: this.searchQuery || this.$route.query.q,
          };
          this.isOpenSearchTooltip = !this.selectedItemValue.city;
        }
      }
    },

    async autoSearch() {
      await this.updateSelectedCity(
        this.selectedContextFlight.destination.city
      );
      await this.updateSearchQuery(this.$route.params, this.selectedItemValue);
    },
  },

  watch: {
    'mapboxService.geocodingClient': {
      immediate: true,
      handler() {
        if (this.$route.query.city && mapboxService.geocodingClient) {
          this.updateSelectedCity(this.$route.query.city);
          if (this.selectedContextFlight && !this.$route.query.q) {
            this.updateSelectedCity(
              this.$route.query.city ||
                this.selectedContextFlight?.destination.city
            );
          }
        }
      },
    },

    ['$route.query']: {
      immediate: true,
      handler(query) {
        if (query) {
          this.searchQuery = query.q || '';
          this.itemFromQuery.q = query.q || '';
          this.itemFromQuery.city = query.city || null;
          this.itemFromQuery.longLat = [query.lng || null, query.lat || null];
          if (Boolean(query.city)) {
            this.updateSelectedCity(query.city);
          }
        }

        if (Boolean(query)) {
          this.isOpenSearchTooltip = true;
        } else {
          this.isOpenSearchTooltip = false;
          this.preSelectedSearch = {
            city: null,
            longLat: null,
          };
        }
      },
    },

    search(val) {
      if (Boolean(val)) {
        this.isOpenSearchTooltip = false;
      } else {
        this.isOpenSearchTooltip = true;
      }
      if (!val || val === this.preSelectedSearch.city) {
        return;
      }
      this.searchCityDebounced(val);
    },

    reverseGeocodeResult(val, oldVal) {
      if (!!val && val !== oldVal) {
        for (let place of val.features) {
          if (place.place_type[0] == 'place') {
            this.updateSearchQuery(this.$route.params, {
              city: place.text,
              longLat: place.center,
            });
          }
        }
      }
    },

    selectedContextFlight(val, oldVal) {
      if (
        !oldVal ||
        (!!val && val.destination.city !== oldVal.destination.city)
      ) {
        if (!this.$route.query?.q) {
          this.autoSearch();
        } else {
          this.$emit('showAlert');
        }
      } else if (!val) {
        this.onClear();
      }
    },
  },

  computed: {
    items() {
      if (!this.hasCitySearch || !this.search) {
        return [];
      } else {
        return this.citySearch.result.features.map((result) => ({
          city: result.text,
          longLat: result.center,
          text: result.place_name,
        }));
      }
    },

    reverseGeocodeResult() {
      return this.reverseGeocode.result;
    },

    preSelectedTextQuery() {
      return this.$route.query.q || '';
    },

    searchResultText() {
      if (!this.$route.query.city && !this.$route.query.q) {
        return '';
      } else {
        return this.$t('discovery.search_query_in_city', {
          query: this.$route.query.q || '',
          city: this.$route.query.city || '',
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .search-input {
  background: white;
}

.v-input__slot .v-text-field__slot > input {
  font-size: 14px;
}

.search-box {
  width: 100%;
  border-radius: 8px;
  position: relative;

  @media (min-width: map-get($grid-breakpoints, lg)) {
    width: 400px;
  }

  &-location {
    &-tooltip {
      position: absolute;
      z-index: 2;
      bottom: -35px;
      background: #05090f;
      border-radius: 8px;
      color: #fff;
      padding: 7px 14px 7px 11px;
      @include font-size(12, 150);

      &.desktop {
        display: none;

        @media (min-width: map-get($grid-breakpoints, sm)) {
          display: block;
          bottom: 40px;
          font-size: 14px;
        }

        @media (min-width: map-get($grid-breakpoints, md)) {
          bottom: -25px;
        }
      }

      &-image {
        margin-left: 14px;
        cursor: pointer;
      }

      &::before {
        content: '';
        position: absolute;
        top: -19px;
        left: 15px;
        width: 10px;
        height: 10px;
        border: 10px solid transparent;
        border-bottom: 10px solid #05090f;
      }
    }
  }

  ::v-deep {
    .v-input__prepend-inner {
      .v-icon {
        color: var(--v-primary-base) !important;
      }
    }
  }
}

.search-button {
  width: 100%;
  @include font-size(16, 150, 600);
  border-radius: 8px;

  @media (min-width: map-get($grid-breakpoints, md)) {
    width: 111px;
  }

  &-mobile {
    width: 100%;
  }
}

::v-deep
  .search-trigger
  .v-text-field.v-text-field--enclosed
  .v-text-field__details {
  display: none !important;
}
::v-deep .search-trigger .v-input__slot {
  margin: 0;
}
::v-deep .search-trigger fieldset {
  background: #fff;
  border: 1px solid #cfd5e0;
}
::v-deep
  .search-trigger
  .v-text-field--rounded
  > .v-input__control
  > .v-input__slot {
  padding: 0 6px;
}
::v-deep .search-trigger .v-input__append-inner {
  margin-bottom: 4px;
  margin-top: 4px !important;
}

::v-deep
  .search-trigger
  .v-text-field--rounded
  > .v-input__control
  > .v-input__slot {
  padding: 0 4px 0 16px;
}
</style>
