<template>
  <v-dialog fullscreen hide-overlay :value="dialog">
    <v-card>
      <v-container class="px-5 py-8">
        <v-row no-gutters class="pb-6">
          <v-col cols="12" class="d-flex justify-space-between">
            <span class="title">
              {{ $t('discovery.search_by_keyword') }}
            </span>
            <span @click="onClose">
              <v-icon> mdi-close </v-icon>
            </span>
          </v-col>
        </v-row>
        <SearchComponent :modalState="modalView" @modalClose="onClose" />
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import SearchComponent from './SearchComponent';

export default {
  name: 'SearchModalComponent',
  components: { SearchComponent },
  props: {
    modalView: Boolean,
  },

  data() {
    return {
      dialog: this.modalView,
    };
  },

  methods: {
    onClose() {
      this.$emit('close');
      this.dialog = false;
    },
  },

  watch: {
    modalView(val) {
      this.dialog = val;
    },
  },
};
</script>

<style lang="scss" scoped>
.title {
  @include font-size(18, 170, 600);
  color: #05090f;
}
</style>
