<template>
  <v-card
    class="position-relative"
    outlined
    color="#F2F4F7"
    data-cy="DiscoveryHeader-search-bar"
  >
    <FlightAlert
      @searchCity="searchCity"
      :link-text="getLinkText"
      ref="FlightAlert"
    />
    <v-container
      class="discovery-header py-0 px-md-0"
      data-cy="DiscoveryHeader-header-title"
    >
      <v-row no-gutters>
        <v-col cols="auto">
          <h1 class="header-title pt-6 pb-sm-5">
            {{ $t('product.find_things_to_do') }}
          </h1>
        </v-col>
      </v-row>
      <v-row align="center" no-gutters :justify="isMobile ? 'center' : 'start'">
        <v-col cols="12">
          <SearchComponent
            :modalState="modalState"
            @search="$emit('click')"
            ref="SearchComponent"
            @showAlert="getAlert"
          />
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import GoogleTagManagerMixins from '@/core/mixins/GoogleTagManagerMixins';
import DiscoveryPageMixins from '@/modules/discovery/mixins/DiscoveryPageMixins';
import SearchComponent from './search/SearchComponent';
import FlightAlert from '@/core/components/FlightAlert';
import SelectedContextFlightMixins from '@/modules/flights/mixins/SelectedContextFlightMixins';

export default {
  name: 'DiscoveryHeader',
  components: { SearchComponent, FlightAlert },
  mixins: [
    GoogleTagManagerMixins,
    DiscoveryPageMixins,
    SelectedContextFlightMixins,
  ],

  props: {
    preSelectedSearch: { type: String },
    query: String,
    modalState: Boolean,
  },

  data() {
    return {
      menu: false,
    };
  },

  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },

    getLinkText() {
      return this.$t('product.search_in_city', {
        city: this.selectedContextFlight?.destination.city,
      });
    },
  },

  methods: {
    searchCity() {
      this.$refs.SearchComponent.autoSearch();
    },

    getAlert() {
      this.$refs.FlightAlert.showAlert();
    },
  },
};
</script>

<style lang="scss" scoped>
.nav-icon {
  cursor: pointer;
}
.nav-text {
  color: var(--v-grey7-base);
  @include font-size(14, 150, 600);
}
.header-title {
  @include font-size(30, 150);
  color: #05090f;
}

::v-deep .v-text-field__slot input {
  @include font-size(16, 150);
  display: flex;
  align-items: center;
  color: #05090f;
}

.search-dropdown {
  width: 100%;
  border-radius: 8px;
}
.trigger {
  cursor: pointer;
}
.search-box {
  width: 100%;
  height: 44px;
  border-radius: 8px;
  position: relative;

  @media (min-width: map-get($grid-breakpoints, lg)) {
    width: 400px;
  }

  &-location {
    &-tooltip {
      position: absolute;
      z-index: 2;
      bottom: -35px;
      background: #05090f;
      border-radius: 8px;
      color: #fff;
      padding: 7px 14px 7px 11px;
      @include font-size(12, 150);

      @media (min-width: map-get($grid-breakpoints, md)) {
        font-size: 14px;
      }

      &-image {
        margin-left: 14px;
        cursor: pointer;
      }

      &::before {
        content: '';
        position: absolute;
        top: -19px;
        left: 15px;
        width: 10px;
        height: 10px;
        border: 10px solid transparent;
        border-bottom: 10px solid #05090f;
      }
    }
  }
}
</style>
